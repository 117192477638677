import React, { Fragment } from 'react';


import {
    AnnotationIcon,
    ChatAlt2Icon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,
    MenuIcon,
    PencilAltIcon,
    QuestionMarkCircleIcon,
    ReplyIcon,
    SparklesIcon,
    TrashIcon,
    UsersIcon,
    XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import SiteHeader from '../components/SiteHeader/type3-1';
import LogoCloud from '../components/LogoCloud';
import SiteNavigation from '../components/SiteHeader/navigation';
import SectionHeader from '../components/SectionHeader';
import KinetikFeature from '../components/KinetikFeature';
import ConsultantFeature from '../components/ConsultantFeature';
import Team from '../components/Team';
import Footer from '../components/Footer';
import ProjectFeature from '../components/ProjectFeature';
import CTA from '../components/CTA';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Type2({ props }) {
    return (
        <div className="bg-white">


            <main>
                <SiteHeader />


                <SectionHeader
                    title="Tjenester"
                    header="For kraft og energi-bransjen."
                    description="Vi leverer egentutviklede tjenester for strøm og energi- bransjen gjennom vår felles merkevare&nbsp;kinetik."
                    backgroundColor="bg-gray-100"
                />

                {/* Kinetik Feature Sections */}
                <KinetikFeature />

                <SectionHeader
                    title="Tjenester"
                    header="Konsulenter til utfordrende&nbsp;oppdrag."
                    description="Vi hjelper gode kunder med spennende oppgaver. Brenner du inne med en god ide eller et problem du trenger å løse ta&nbsp;kontakt."
                    backgroundColor="bg-white"
                />

                {/* Consultant Feature Sections */}
                <ConsultantFeature />

                <SectionHeader
                    title="Prosjekter"
                    header="Noe av det vi har laget for&nbsp;andre."
                    description="Her er noe av det vi har syslet med den siste tiden som en del av konsulentleveransen&nbsp;vår."
                    backgroundColor="bg-gray-100"
                />

                <ProjectFeature />




            </main>
            <Footer />

        </div>
    )
}

export default Type2;
