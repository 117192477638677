/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

// @ts-ignore
import illustration from '../../../static/illustration-3.svg';

// @ts-ignore
import banner from '../../../static/BannerImage5.jpg';

import SiteNavigation from './navigation'
import { StaticImage } from 'gatsby-plugin-image'
import LogoCloud from '../LogoCloud'
import { GiElectric } from 'react-icons/gi'


/*
        <img
          className="h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1604238376153-c7dfd9522fa7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2144&q=80"
          alt="" />

                  <StaticImage
          className="h-full w-full object-cover"
          alt="bortgjemte data"
          src="../../../static/BannerImage3.jpg"
        />
*/

export default function SiteHeader() {
  return (

    <div className="relative overflow-hidden">
      <div className="absolute inset-0">

        <StaticImage
          className="h-full w-full"
          alt="bortgjemte data"
          src="../../../static/Hero/BannerImage67.jpg"
        />
        <div className="absolute inset-0" />
      </div>
      <SiteNavigation bgColor="bg-transparent" />
      <div className="relative pt-10 sm:pt-16 lg:pt-8 lg:overflow-hidden">
        <div className="mx-auto max-w-7xl lg:px-8 md:pb-12">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div className="lg:py-24">
                <a
                  href="#"
                  className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                >
                  <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-convivial rounded-full">
                    Kinetik Nett
                  </span>
                  <span className="ml-4 text-sm">Vårt produkt for nettselskaper</span>
                  <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-900" aria-hidden="true" />
                </a>
                <h1 className="mt-4 text-4xl tracking-tight font-header font-extrabold text-gray-900 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl font-header">
                  <span className="block">Vi er eksperter på</span>
                  <span className="block text-convivial">bortgjemte data</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl lg:pr-12">
                  Vi i Convivial tror at morgendagens vinnere vil være de som tør å åpne opp, tilgjengeliggjøre bortgjemte data og ta i bruk ny teknologi for å skape de
                  beste&nbsp;brukeropplevelsene.
                </p>
              </div>
            </div>

          </div>
        </div>
        <LogoCloud bgColor="bg-transparent" product={['generic']} />
      </div>
    </div>
  )
}

/*
          <StaticImage
            className="h-full w-full object-cover"
            alt="bortgjemte data"
            src="../../../static/BannerImage.png"
          />
*/

/*                    <img
className=" w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
src={illustration}
alt="
*/