import React from "react";

import { GiElectric } from 'react-icons/gi';
import { RiWaterFlashFill } from 'react-icons/ri';

// @ts-ignore
import KinetikNettGif from '../../../static/KinetikNett/kinetiknett.gif';

// @ts-ignore
import KinetikNettMp4 from '../../../static/KinetikNett/kinetiknett.mp4';

// @ts-ignore
import KinetikKraftDashboard from '../../../static/KinetikKraft/dashboard.png';

// @ts-ignore
import KinetikKraftMp4 from '../../../static/KinetikKraft/dashboard.mp4';

import { StaticImage } from "gatsby-plugin-image";

function KinetikFeature() {
    return (
        <div className="relative pt-16 pb-32 overflow-hidden">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div>
                                <span className="h-12 w-12 rounded-md flex items-center justify-center border-2">
                                    <GiElectric size="32" className="text-convivial" />
                                </span>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                    Kinetik Nett
                                </h2>
                                <p className="mt-4 text-lg text-gray-500">
                                    Med Kinetik Nett får nettselskapene et brukervennlig og moderne verktøy som gjør det enkelt å finne bortgjemte data og løse oppgaver i en travel&nbsp;hverdag.
                                </p>
                                <div className="mt-6">
                                    <a
                                        href="/kinetik/nett"
                                        className="inline-flex px-4 py-2 border border-transparent bg-convivial text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                                    >
                                        Les mer
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 border-t border-gray-200 pt-6">
                            <blockquote>
                                <div>
                                    <p className="text-base text-gray-500">
                                        &ldquo;Brukervennlig og effektiv kart-løsning som gir Midtkraft Nett god oversikt i forsyningsområdet.
                                        Kinetik Nett gir oss muligheten for effektiv varsling av kunder, samt redusert responstid ved feil.&rdquo;
                                    </p>
                                </div>
                                <footer className="mt-3">
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <StaticImage className="h-6 w-6 rounded-full" src="../../../static/KinetikNett/JensKristian.jpg" alt="Jens Kristian Støa" />
                                        </div>
                                        <div className="text-base font-medium text-gray-700">
                                            Jens Kristian Støa, Midtkraft Nett
                                        </div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <video src={KinetikNettMp4} controls={false} muted autoPlay loop={true} className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none">
                                Video not supported in your browser
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div>
                                <span className="h-12 w-12 rounded-md flex items-center justify-center border-2">
                                    <RiWaterFlashFill size="32" className="text-convivial" />
                                </span>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                    Kinetik Kraft
                                </h2>
                                <p className="mt-4 text-lg text-gray-500">
                                    Med Kinetik Kraft får kraftprodusentene kraftverket rett i lomma. Verktøyet gir full oversikt over sanntidsdata, sanntidsanalyser, økonomi og helsetilstanden i kraftverket.
                                </p>
                                <div className="mt-6">
                                    <a
                                        href="/kinetik/kraft"
                                        className="inline-flex px-4 py-2 border border-transparent bg-convivial text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                                    >
                                        Les mer
                                    </a>
                                </div>
                            </div>
                            <div className="mt-8 border-t border-gray-200 pt-6">
                                <blockquote>
                                    <div>
                                        <p className="text-base text-gray-500">
                                            &ldquo;Løsningen gir ledelse og driftspersonell tilgang til alle nødvendige data fra kraftverkene i porteføljen for å kunne drifte anleggene effektivt, unngå kostbare havarier og utnytte restlevetiden til aggregatene maksimalt.&rdquo;
                                        </p>
                                    </div>
                                    <footer className="mt-3">
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <StaticImage className="h-6 w-6 rounded-full" src="../../../static/KinetikKraft/arne-hanto.jpg" alt="Arne Hanto Moen" />
                                            </div>
                                            <div className="text-base font-medium text-gray-700">
                                                Arne Hanto Moen, Øvre Eiker Energi
                                            </div>
                                        </div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <video src={KinetikKraftMp4} controls={false} muted autoPlay loop={true}
                                className="w-full rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none">
                                Video not supported in your browser
                            </video>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default KinetikFeature;


/*
<StaticImage
                                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                src={KinetikKraftDashboard}
                                alt="Kinetik kraft dashboard" />
*/
